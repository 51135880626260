import React from 'react';
import PropTypes from 'prop-types';

function ProgressBar({
  contentName,
  current,
  total,
  color,
  handleClick,
}) {
  const percentage = Math.round((current / total) * 100) || 0;

  return (
    <div className="progress-bar-container">
      <div className="progress-bar-wrapper">
        <div className="progress-details">
          {contentName ? (
            <div className="progress-bar-text">
              <span className="progress-bar-name">{contentName}</span>
              {`${current} of ${total}`}
            </div>
          ) : (
            <div className="progress-bar-text">{`${current} of ${total}`}</div>
          )}
          <div className="progress-bar-percentage">{`${percentage}%`}</div>
        </div>
        <div
          className="progress-bar"
          style={{
            cursor: color === '#1C75BC' ? 'pointer' : 'pointer',
          }}
          onClick={handleClick}
          role="presentation"
        >
          <div
            className="progress-bar-fill"
            style={{
              width: `${percentage}%`,
              backgroundColor: color,
            }}
          />
        </div>
      </div>
    </div>
  );
}

// Add prop types validation
ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  contentName: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default ProgressBar;
