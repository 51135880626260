/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import popScheduleApi from '../../utils/api/pop-schedule';
import { setErrorNotification } from './NotificationSlice';
import { changeDateTimeSecondsFormat, convertSecondsToHoursAndMinutes } from '../../utils/helpers';

export const fetchPOPScheduleList = createAsyncThunk(
  'pop/',
  async (data, { dispatch }) => {
    const response = await popScheduleApi.getPopSchedule(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        scheduleStartDate: changeDateTimeSecondsFormat(val?.schedule_start),
        scheduleEndDate: changeDateTimeSecondsFormat(val?.schedule_end),
        deviceOff: convertSecondsToHoursAndMinutes(val?.total_off_time),
        outOfSync: convertSecondsToHoursAndMinutes(val?.out_Of_sync),
        matchingSchedule: convertSecondsToHoursAndMinutes(val?.matching_schedule),
        completionPercentage: `${val?.percentage}%`,
        sequentialData: (val?.sequential_data || []).map((sequential) => ({
          ...sequential,
          startsAt: changeDateTimeSecondsFormat(sequential?.startTime),
          stopsAt: changeDateTimeSecondsFormat(sequential?.endTime),
          duration: convertSecondsToHoursAndMinutes(sequential?.matchedTime),
        })),
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);
export const fetchSinglePopUpdateList = createAsyncThunk(
  'pop/fetchSinglePopUpdateList',
  async (data, { dispatch }) => {
    const response = await popScheduleApi.updatePopSchedule(data)
      .then((response1) => {
        const tableValues = {
          ...response1?.data,
          scheduleStartDate: changeDateTimeSecondsFormat(response1?.data.schedule_start),
          scheduleEndDate: changeDateTimeSecondsFormat(response1?.data.schedule_end),
          deviceOff: convertSecondsToHoursAndMinutes(response1?.data.total_off_time),
          outOfSync: convertSecondsToHoursAndMinutes(response1?.data.out_Of_sync),
          matchingSchedule: convertSecondsToHoursAndMinutes(response1?.data?.matching_schedule),
          completionPercentage: `${response1?.data.percentage}%`,
          sequentialData: response1?.data?.sequential_data.map((sequential) => ({
            ...sequential,
            startsAt: changeDateTimeSecondsFormat(sequential?.startTime),
            stopsAt: changeDateTimeSecondsFormat(sequential?.endTime),
            duration: convertSecondsToHoursAndMinutes(sequential?.matchedTime),
          })),
        };
        return { ...response1?.data, results: tableValues, code: 200 };
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
    return response;
  },
);

const initialState = {
  popData: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  tableFilter: {},
};

export const popScheduleSlice = createSlice({
  name: 'popSchedule',
  initialState,
  reducers: {
    setCount: (state) => {
      state.pageCount = 1;
      state.totalPageCount = 1;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetPopState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed

    builder
      .addCase(fetchPOPScheduleList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPOPScheduleList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action.payload.code === 200) {
          const data = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.popData = data;
          } else {
            state.popData.push(...data);
          }
        }
      })
      .addCase(fetchPOPScheduleList.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchSinglePopUpdateList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSinglePopUpdateList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          const fetchedData = action.payload.results;
          const existingData = state.popData || [];
          const filteredData = existingData.map((item) => (
            item._id === fetchedData._id ? fetchedData : item
          ));
          state.popData = filteredData;
        }
      })
      .addCase(fetchSinglePopUpdateList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setCount,
  setFilter,
  setTableFilter,
  resetPopState,
} = popScheduleSlice.actions;

export default popScheduleSlice.reducer;
