/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { toPng } from 'html-to-image';
import cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import ModalBox from '../../../../shared/component/ModalBox';
import TemplateContentUpload from '../TemplateContentUpload';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
// import backGround from '../../../../assets/images/template-1background.png';
import PopupBox from '../ContentUploadConfirmation';
import FileFormat from '../FileFormat';

function HtmlTemplateOne({ templateData }) {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(false);
  const [disable, setDisable] = useState(true);
  const ref = useRef(null);
  const currentLanguageCode = cookies?.get('i18next') || 'en';
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = () => {
    toPng(ref?.current, { cacheBust: true })
      .then((dataUrl) => {
        const base64String = dataUrl;
        setBase64(base64String);
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (ref.current) {
      const content = ref.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
            <style>
              body { box-sizing: border-box; height: 100vh !important; margin: 0;} 
      p, h1 { margin: 0; padding: 0; } 
      .template1-title
      { margin-top: 20px !important; }
      .template-1-bg{
        padding-bottom: 0px !important;
        height: 100% !important;
      }
      .template-1-content{
        height: 100% !important;
        padding: 0 !important;
      }
      .template1-title{
        padding: 0 !important;
        margin: 0 !important;
        font-size: 25px !important;
      }
      .template-1-category{
        font-size: 20px !important;
      }
      .template-1-price-header{
        font-size: 20px !important;
      }
      .template-1-list-items{
        font-size: 15px !important;
      }
      @media (max-width: 1535px) {
      .template1-title{
        padding: 0 !important;
        margin: 0 !important;
        font-size: 15px !important;
      }
      .template-1-category{
        font-size: 15px !important;
        padding: 2px !important;
      }
      .template-1-price-header{
        font-size: 15px !important;
        padding: 2px !important;
      }
      .template-1-list-items{
        font-size: 15px !important;
        padding: 2px !important;
      }
      .template-1-desc{
        font-size: 15px !important;
        padding: 0px !important;
      }
      }
      @media(max-width : 877px){
        .template-1-category{
        font-size: 10px !important;
        padding: 2px !important;
      }
      .template-1-price-header{
        font-size: 10px !important;
        padding: 0px !important;
      }
      .template-1-list-items{
        font-size: 10px !important;
        padding: 0px !important;
      }
      .template-1-desc{
        font-size: 10px !important;
        padding: 0px !important;
      }
      .template-1-price{
      font-size: 10px !important;
      }
      }
      @media(max-width : 614px){
        .template-1-category{
        font-size: 5px !important;
        padding: 2px !important;
      }
      .template-1-price-header{
        font-size: 5px !important;
        padding: 0px !important;
      }
      .template-1-list-items{
        font-size: 5px !important;
        padding: 0px !important;
      }
      .template-1-desc{
        font-size: 5px !important;
        padding: 0px !important;
      }
      }
            </style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils.initializer(templateData).then((res) => (setData(res)));
    utils.reload();
  }, [templateData, notification, openContentUploadConfirmation]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used setTimeout to ensure the page was fully rendered before calling the API */
      /* And then waited for the response */
      setTimeout(() => {
        convertBase64();
      }, 800);
      convertHtml();
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        setNotification={setNotification}
        fileType={fileType}
        htmlContent={htmlContent}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };

  const templateContent = data?.[0]?.template_contents?.items;
  const unhideCategory = templateContent?.filter((filterHide) => !filterHide?.hide);
  const backgroundImage = data?.[0]?.template_backgroundImage_url;
  return (

    <div style={{ backgroundColor: 'white' }}>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      {data?.map((item) => (
        <div
          ref={ref}
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            width: '100%',
            height: 'auto',
            paddingBottom: '50px',
          }}
          className="template-1-bg"
        >
          <div
            key={item}
            style={{
              marginLeft: '30px',
            }}
            className="template-1-content"
          >
            <div
              style={{ padding: '15px' }}
            >
              <div
                style={{ textAlign: 'center' }}
              >
                <h1
                  className="template1-title"
                  style={{
                    textAlign: 'center',
                    color: `${data?.[0]?.styles?.title_style?.color}`,
                    fontSize: `${data?.[0]?.styles?.title_style?.fontSize}`,
                    paddingBottom: '20px',
                    marginTop: '8%',
                    fontFamily: `${data?.[0]?.styles?.title_style?.fontFamily}`,
                  }}
                >
                  {data?.[0]?.template_title}
                </h1>
              </div>
            </div>
            <div style={currentLanguageCode === 'ar' ? { marginRight: '50px' } : { marginRight: '0px' }}>
              <div
                style={{ display: 'block', width: '95%' }}
              >
                {item?.template_contents?.items?.filter((filterHide) => !filterHide.hide)
                  ?.map((category, catIndex) => (
                    <div
                      key={category}
                    >
                      <div
                        style={{ display: 'flex' }}
                      >
                        <h1
                          style={{
                            display: 'flex',
                            width: '50%',
                            paddingTop: '15px',
                            color: `${data?.[0]?.styles?.cate_style?.color}`,
                            margin: '0px',
                            fontSize: `${data?.[0]?.styles?.cate_style?.fontSize}`,
                            fontFamily: `${data?.[0]?.styles?.cate_style?.fontFamily}`,
                          }}
                          className="template-1-category"
                        >
                          {category?.category}
                        </h1>
                        <div
                          style={{
                            marginLeft: '2%',
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'flex-end',
                            width: '50%',
                          }}
                        >
                          <p
                            style={{
                              paddingTop: '20px',
                              margin: '0px',
                              color: `${data?.[0]?.styles?.price_header_style?.color}`,
                              fontSize: `${data?.[0]?.styles?.price_header_style?.fontSize}`,
                              fontFamily: `${data?.[0]?.styles?.price_header_style?.fontFamily}`,
                              padding: '0px',
                              fontWeight: 400,
                            }}
                            className="template-1-price-header"
                          >
                            Small
                          </p>
                          <p
                            style={{
                              margin: '0px',
                              color: `${data?.[0]?.styles?.price_header_style?.color}`,
                              fontSize: `${data?.[0]?.styles?.price_header_style?.fontSize}`,
                              fontFamily: `${data?.[0]?.styles?.price_header_style?.fontFamily}`,
                              fontWeight: 400,
                            }}
                            className="template-1-price-header"
                          >
                            Medium
                          </p>
                          <p
                            style={{
                              margin: '0px',
                              paddingTop: '20px',
                              color: `${data?.[0]?.styles?.price_header_style?.color}`,
                              fontSize: `${data?.[0]?.styles?.price_header_style?.fontSize}`,
                              fontFamily: `${data?.[0]?.styles?.price_header_style?.fontFamily}`,
                              padding: '0px',
                              fontWeight: 400,
                            }}
                            className="template-1-price-header"
                          >
                            Large
                          </p>
                        </div>
                      </div>
                      {unhideCategory?.[catIndex]?.details?.map((val, valIndex) => (
                        <div
                          style={
                            {
                              display: 'flex', alignItems: 'center',
                            }
                          }
                        >
                          <div
                            style={
                              {
                                display: 'flex', flexDirection: 'column', gap: '0px', margin: '5px', width: '55%',
                              }
                            }
                          >
                            <p
                              style={{
                                wordWrap: 'break-word',
                                color: `${data?.[0]?.styles?.item_style?.color}`,
                                margin: '0px',
                                padding: '5px',
                                fontSize: `${data?.[0]?.styles?.item_style?.fontSize}`,
                                fontFamily: `${data?.[0]?.styles?.item_style?.fontFamily}`,
                              }}
                              className="template-1-list-items"
                            >
                              {val?.name}
                            </p>
                            <p
                              style={{
                                color: `${data?.[0]?.styles?.desc_style?.color}`,
                                wordWrap: 'break-word',
                                margin: '0px',
                                padding: '5px',
                                fontSize: `${data?.[0]?.styles?.desc_style?.fontSize}`,
                                fontFamily: `${data?.[0]?.styles?.desc_style?.fontFamily}`,
                              }}
                              className="template-1-desc"
                            >
                              {val?.desc}
                            </p>
                          </div>
                          <div
                            style={{
                              color: 'white',
                              fontSize: '25px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '40%',
                            }}
                          >
                            <p
                              style={{
                                padding: '0px',
                                margin: '0px',
                                color: `${data?.[0]?.styles?.price_style?.color}`,
                                fontSize: `${data?.[0]?.styles?.price_style?.fontSize}`,
                                fontFamily: `${data?.[0]?.styles?.price_style?.fontFamily}`,
                                width: '15%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              className="template-1-price"
                            >
                              {unhideCategory?.[catIndex]?.pricing?.[valIndex]?.p1 || '-'}
                            </p>
                            {/* </div> */}
                            <p
                              style={{
                                margin: '0px',
                                color: `${data?.[0]?.styles?.price_style?.color}`,
                                fontSize: `${data?.[0]?.styles?.price_style?.fontSize}`,
                                fontFamily: `${data?.[0]?.styles?.price_style?.fontFamily}`,
                                width: '15%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              className="template-1-price"
                            >
                              {unhideCategory?.[catIndex]?.pricing?.[valIndex]?.p2 || '-'}
                            </p>
                            <p
                              style={{
                                padding: '0px',
                                margin: '0px',
                                color: `${data?.[0]?.styles?.price_style?.color}`,
                                fontSize: `${data?.[0]?.styles?.price_style?.fontSize}`,
                                fontFamily: `${data?.[0]?.styles?.price_style?.fontFamily}`,
                                width: '15%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              className="template-1-price"
                            >
                              {unhideCategory?.[catIndex]?.pricing?.[valIndex]?.p3 || '-'}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ))}

    </div>
  );
}

HtmlTemplateOne.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};
export default HtmlTemplateOne;
