import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/component/Button';
import subscription from '../../../utils/api/subscription';
import { currencySymbol, useRecaptcha } from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { RadioButton } from '../../../shared/component/form/Input';
import { getStoragePayment } from '../../../redux/slices/SubscriptionSlice';

function StorageChangePaymentMode(props) {
  const dispatch = useDispatch();
  const { VerifyRecaptcha } = useRecaptcha();
  const {
    backModal,
    currency,
    storageInfo,
    // paymentInfo,
    viewStorageSummary,
    closeModal,
    payment,
  } = props;
  const { storagePaymentMode } = useSelector((state) => state?.subscription);
  // const [payment, setPayment] = useState({});
  const [disable, setDisable] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');
  const [paymentMode, setPaymentMode] = useState(storagePaymentMode || '');

  const { t } = useTranslation();

  const paymentModeOptions = [
    {
      label: t('online'),
      key: 'online',
    },
    {
      label: t('offline'),
      key: 'offline',
    },
  ];
  const restrictedCurrencies = ['USD', 'SAR']; // Currencies where "online" is allowed
  const filterPaymentMethod = restrictedCurrencies.includes(currency)
    ? paymentModeOptions
    : paymentModeOptions.filter((option) => option.key !== 'online');
  const handleChangePayment = (value) => {
    if (value) {
      setPaymentMode(value);
      dispatch(getStoragePayment(value));
    }
  };

  const submitDevice = async () => {
    const recaptchaToken = await VerifyRecaptcha('plan-change-summary');
    setDisable(true);
    setButtonStatus('loading');
    if (paymentMode === 'online') {
      const request = {
        payment_mode: 'online',
        storage: storageInfo.totalStorage,
        recaptchaToken,
      };
      subscription.storageIncreaseCount(request)
        .then((res) => {
          setDisable(false);
          setButtonStatus('');
          if (res?.data?.targetUrl && res?.data?.payid) {
            const newWindow = window.open('', '_blank');
            console.log(newWindow, 'dfkdbhxj');
            if (!newWindow) {
              dispatch(
                setErrorNotification(
                  { message: 'Popup blocked! Please allow popups in your browser settings.' },
                ),
              );
            }
            const paymentUrl = `${res?.data?.targetUrl}?paymentid=${res?.data?.payid}`;
            newWindow.location.href = paymentUrl; // Update the blank window with the URL
            newWindow.focus();
            closeModal(false);
          } else {
            closeModal(false);
            dispatch(
              setErrorNotification(
                { message: 'There\'s a temporary issue with our payment system. Please try again later.' },
              ),
            );
          }
        })
        .catch((err) => {
          setDisable(false);
          setButtonStatus('');
          dispatch(setErrorNotification(err?.response?.data));
        });
    } else {
      viewStorageSummary(storageInfo, payment);
    }
  };

  // const getSummaryData = () => {
  //   const request = {
  //     storage: storageInfo.totalStorage,
  //   };
  //   subscription.storageIncreaseCountSummary(request).then((response) => {
  //     setPayment((prevValue) => ({
  //       ...prevValue,
  //       planName: response?.data?.plan_name,
  //       unitPrice: response?.data?.unit_price,
  //       storageCount: response?.data?.quantity,
  //       totalPrice: response?.data?.price,
  //       paymentSummary: response?.data?.payment_summary,
  //       actualPrice: response?.data?.actual_price,
  //     }));
  //   }).catch((error) => {
  //     dispatch(setErrorNotification(error?.response?.data));
  //     backModal();
  //   });
  // };

  // useEffect(() => {
  //   getSummaryData();
  // }, []);

  useEffect(() => {
    if (paymentMode) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [paymentMode]);
  return (
    <div className="device-change-summary">
      <div className="header">
        {/* <div className="test-title">{t('account')}: {planInformation.account}</div> */}
        <div className="test-title">{t('planName')}: {payment.planName}</div>
      </div>
      <div className="device-change-content">
        <div className="sass-summary">
          <table>
            <tr>
              <td colSpan="4">
                <div className="table-header">
                  <span>{t('saasSummary')}</span>
                  <div className="success-link" onClick={backModal} role="presentation">{t('editStorage')}</div>
                </div>
              </td>
            </tr>
            <tr>
              <th>{t('description')}</th>
              <th>{t('unitPrice')}</th>
              <th>{t('quantity')}</th>
              <th>{t('totalPrice')}</th>
            </tr>
            <tr>
              <td>{t('addOnStorage')}</td>
              <td>{currencySymbol(currency, payment.unitPrice)}</td>
              <td>{payment.storageCount > 0 && '+ '}{payment.storageCount} {t('gb')}</td>
              <td>{currencySymbol(currency, payment?.actualPrice?.toFixed(2))}</td>
            </tr>
          </table>
        </div>
      </div>
      {
        payment?.paymentSummary?.debitAmount > 0 && (
          <div className="payment-summary">
            <div className="title">{t('paymentSummary')}</div>
            <div className="content">
              <p>{t('totalAmount')}</p>
              <snap className="success-text">
                {currencySymbol(currency, payment?.actualPrice?.toFixed(2))}
              </snap>
            </div>
            <div className="content">
              <p>{t('amountAddWalletMessage')}</p>
              <snap className="text-danger">
                -{currencySymbol(currency, payment?.paymentSummary?.debitAmount?.toFixed(2))}
              </snap>
            </div>
            <div className="content bt-dot">
              <p>{t('amountToBePaid')}</p>
              <snap>
                {currencySymbol(currency, payment?.totalPrice?.toFixed(2))}
              </snap>
            </div>
          </div>
        )
      }
      {
        payment?.paymentSummary?.creditAmount > 0 && (
          <div className="payment-summary">
            <div className="title">{t('paymentSummary')}</div>
            <div className="content">
              <p>{t('wallet')}</p>
              <snap className="text-success">
                +{currencySymbol(currency, payment?.paymentSummary?.creditAmount?.toFixed(2))}
              </snap>
            </div>
          </div>
        )
      }
      <div className="payment-mode">
        <h2 className="payment-title">{t('paymentMode')}</h2>
        <div className="payment-options-wrap">
          {filterPaymentMethod?.map((item) => (
            <div className="each-content-option">
              <RadioButton
                checked={paymentMode === item.key}
                label={item?.label}
                value={item?.key}
                onChange={handleChangePayment}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="form-button-group pr-10 pb-10">
        <div className="form-button" style={{ width: '5vw' }}>
          <Button label={t('back')} click={backModal} classes="default-button" />
        </div>
        <div className="form-button">
          <Button
            label={t('continue')}
            click={submitDevice}
            classes={buttonStatus === 'loading'
              ? 'success-button loading-btn'
              : 'success-button'}
            disabled={disable}
            loading={buttonStatus === 'loading'}
          />
        </div>
      </div>
    </div>
  );
}

StorageChangePaymentMode.propTypes = {
  viewStorageSummary: PropTypes.func.isRequired,
  backModal: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  // paymentInfo: PropTypes.string.isRequired,
  storageInfo: PropTypes.shape({
    totalStorage: PropTypes.number,
  }).isRequired,
  closeModal: PropTypes.node.isRequired,
  payment: PropTypes.objectOf().isRequired,
};

export default StorageChangePaymentMode;
