/* eslint no-underscore-dangle: 0 */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toPng } from 'html-to-image';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import PopupBox from '../ContentUploadConfirmation';
import HtmlHeader from '../HtmlHeader';
import TemplateContentUpload from '../TemplateContentUpload';
import utils from '../templateFunctions';
import ModalBox from '../../../../shared/component/ModalBox';

import RightIcon from '../../../../assets/images/Templates/goVegan.jpg';
import FileFormat from '../FileFormat';

function HtmlTemplateTen({ templateData }) {
  const [data, setData] = useState([templateData]);
  const [notification, setNotification] = useState(false);
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [disable, setDisable] = useState(true);
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const { t } = useTranslation();
  const ref = useRef(null);
  const dispatch = useDispatch();

  const cssStyles = `
  body {
      box-sizing: inherit;
      width: 100%;
      margin: 0px;
      padding: 0px;
  }
  
  .template-container-10 .template-10-container {
      padding: 5% 10%;
      background-color: #88ad4d;
  }
  
  .template-container-10 .template-10-container .container {
      background-color: #f1e6d4;
  }
  
  .template-container-10 .template-10-container .container .menu {
      padding: 2%;
  }
  
  .template-container-10 .template-10-container .container .menu h3 {
      margin: 0;
  }
  
  .template-container-10 .template-10-container .container .menu p {
      margin: 0;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight {
      display: flex;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .title {
      margin: 0%;
      display: flex;
      align-items: center;
      width: 50%;
      font-size: 40px;
      color: #75ae43;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .title h2 {
      margin: 0;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .images-container {
      display: flex;
      justify-content: flex-end;
      width: 50%;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .images-container .images {
      width: 176px;
      height: 184px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part {
      display: flex;
      flex-direction: column;
      gap: 10px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special {
      display: flex;
      gap: 10%;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 45%;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .starter h3 {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item {
      display: grid;
      grid-template-columns: 80% 16%;
      gap: 10px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .topicRight {
      display: flex;
      flex-direction: column;
      gap: 5px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .price-value {
      display: flex;
      justify-content: flex-end;
      align-items: center;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .price-value p {
      text-align: center;
      padding: 3px;
      color: #75ae43;
      border: solid #75ae43 2px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 {
      display: grid;
      grid-template-columns: 16% 80%;
      gap: 10px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .price-style {
      display: flex;
      justify-content: flex-start;
      align-items: center;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .price-style p {
      text-align: center;
      padding: 3px;
      border: solid #75ae43 2px;
      color: #75ae43;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .topic {
      display: flex;
      flex-direction: column;
      gap: 5px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .price1 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .price1 p {
      padding: 5px;
      color: #75ae43;
      border: solid #75ae43 2px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 45%;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left .category-title {
      background-color: #75ae43;
      text-align: center;
      padding: 3px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left .item {
      display: grid;
      grid-template-columns: 80% 16%;
      gap: 10px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left .item .topic {
      display: flex;
      flex-direction: column;
      gap: 10px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left .item .price1 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left .item .price1 p {
      text-align: center;
      padding: 3px;
      color: #75ae43;
      border: solid #75ae43 2px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left .platter {
      height: 354px;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .category-title {
      background-color: #75ae43;
      text-align: center;
      padding: 3px;
  }
  
  .template-container-10 .template-10-container .container .menu .email {
      display: flex;
      justify-content: flex-end;
      font-size: larger;
      font-weight: bold;
  }
  
  @media screen and (min-width: 1920px) {
  .template-container-10 .template-10-container {
      padding: 3.5% 12%;
      height: 90%;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .images-container .images {
      width: 176px;
      height: 150px;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .title h2 p {
      font-size: 35px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .topicRight h3 {
      font-size: 16px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .topicRight p {
      font-size: 14px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .price-value p {
      font-size: 14px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .starter h3 {
      font-size: 19px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .category-title {
      font-size: 19px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .topic h3 {
      font-size: 16px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .topic p {
      font-size: 14px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .price-style p {
      font-size: 14px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left .platter {
      height: 280px;
  }
  }
  
  @media screen and (min-width: 1550px) and (max-width: 1919px) {
  .template-container-10 .template-10-container {
      padding: 3% 11%;
      height: 90%;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .images-container .images {
      width: 176px;
      height: 145px;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .title h2 p {
      font-size: 30px !important;
  }
  
  .template-container-10 .template-10-container
  .container .menu .content-part .special .right .item .topicRight h3 {
      font-size: 14px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .topicRight p {
      font-size: 12px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .price-value p {
      font-size: 12px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .starter h3 {
      font-size: 17px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .category-title {
      font-size: 17px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .topic h3 {
      font-size: 14px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .topic p {
      font-size: 12px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .price-style p {
      font-size: 12px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left .platter {
      height: 250px;
  }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1549px) {
  .template-container-10 .template-10-container {
      padding: 4% 15%;
      height: 97%;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .images-container .images {
      width: 176px;
      height: 110px;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .title h2 p {
      font-size: 20px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .topicRight h3 {
      font-size: 8px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .topicRight p {
      font-size: 7px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .price-value p {
      font-size: 7px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .starter h3 {
      font-size: 7.5px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .category-title {
      font-size: 7.5px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .topic h3 {
      font-size: 8px !important;
  }
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .topic p {
      font-size: 7px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .price-style p {
      font-size: 7px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left .platter {
      height: 150px;
  }
  }
  
  @media screen and (max-width: 768px) {
  .template-container-10 .template-10-container {
      padding: 0% 13%;
      height: 90%;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .images-container .images {
      width: 176px;
      height: 90px;
  }
  
  .template-container-10 .template-10-container .container .menu .iconRight .title h2 p {
      font-size: 20px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .topicRight h3 {
      font-size: 6px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .topicRight p {
      font-size: 5px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item .price-value p {
      font-size: 5px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .starter h3 {
      font-size: 6px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .category-title {
      font-size: 9px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .topic h3 {
      font-size: 6px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .topic p {
      font-size: 5px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .right .item1 .price-style p {
      font-size: 5px !important;
  }
  
  .template-container-10 .template-10-container .container .menu .content-part .special .left .platter {
      height: 160px;
  }
  }
    `;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = () => {
    toPng(ref?.current, { cacheBust: true })
      .then((dataUrl) => {
        // Save the Base64 string in the state
        setBase64(dataUrl);
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (ref.current) {
      const content = ref.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link 
            href="https://fonts.googleapis.com/css2?family=Paytone+One&family=Poppins:wght@400;500;600&display=swap" 
            rel="stylesheet" />
            <style>${cssStyles}</style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData, notification, openContentUploadConfirmation]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used setTimeout to ensure the page was fully rendered before calling the API */
      /* And then waited for the response */
      setTimeout(() => {
        convertBase64();
      }, 800);
      convertHtml();
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        setNotification={setNotification}
        fileType={fileType}
        htmlContent={htmlContent}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };

  const inner = data?.[0]?.template_contents.items;
  const style = data?.[0]?.styles;
  const unhideCategory = inner?.filter((filterHide) => !filterHide?.hide);
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />

      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      <div className="header-height" />
      <div className="template-container-10" ref={ref}>
        <div className="template-10-container">
          <div className="container">
            {data?.map((item) => (
              <div className="menu">
                <div className="iconRight">
                  <div
                    className="title"
                  >
                    <h2 className="title-content">
                      <p
                        style={{
                          color: `${style?.title_style?.color}`,
                          fontSize: `${style?.title_style?.fontSize}`,
                          fontFamily: `${style?.title_style?.fontFamily}`,
                        }}
                      >
                        {item?.template_title}
                      </p>
                      <p
                        style={{
                          color: `${style?.sub_title_style?.color}`,
                          fontSize: `${style?.sub_title_style?.fontSize}`,
                          fontFamily: `${style?.sub_title_style?.fontFamily}`,
                        }}
                      >
                        {item?.template_sub_title}
                      </p>
                    </h2>
                  </div>
                  <div className="images-container">
                    <img src={RightIcon} alt="" className="images" />
                  </div>
                </div>
                <div className="content-part">
                  <div className="special">
                    {unhideCategory.slice(0, 2)
                      ?.map((categoryContent) => (
                        <div className="right">
                          <div className="starter">
                            <h3
                              className="category-title"
                              style={{
                                border: '2px solid #75AE43',
                                textAlign: 'center',
                                background: '#75AE43',
                                color: `${style?.cate_style?.color}`,
                                fontSize: `${style?.cate_style?.fontSize}`,
                                fontFamily: `${style?.cate_style?.fontFamily}`,
                              }}
                            >
                              {categoryContent?.category}
                            </h3>
                          </div>

                          {categoryContent?.details?.map((val, valIndex) => (
                            <div className="item">
                              <div className="topicRight">
                                <h3
                                  className="priceTag"
                                  style={{
                                    color: `${style?.item_style?.color}`,
                                    fontSize: `${style?.item_style?.fontSize}`,
                                    fontFamily: `${style?.item_style?.fontFamily}`,
                                  }}
                                >
                                  {val?.name}
                                </h3>
                                <p
                                  className="mobileView"
                                  style={{
                                    color: `${style?.desc_style?.color}`,
                                    fontSize: `${style?.desc_style?.fontSize}`,
                                    fontFamily: `${style?.desc_style?.fontFamily}`,
                                  }}
                                >
                                  {val?.desc}
                                </p>
                              </div>
                              {categoryContent?.pricing[valIndex]?.p1 !== ''
                                && (
                                  <div className="price-value">
                                    <p
                                      style={{
                                        color: `${style?.price_style?.color}`,
                                        fontSize: `${style?.price_style?.fontSize}`,
                                        fontFamily: `${style?.price_style?.fontFamily}`,
                                      }}
                                    >
                                      {categoryContent?.pricing[valIndex]?.p1}
                                    </p>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                  <div className="special">
                    <div className="left">
                      {item?.template_images?.[0]?.image !== null
                        && (
                          <img className="platter" src={item?.template_images?.[0]?.image} alt="" />
                        )}
                    </div>
                    {unhideCategory?.[2] && (
                      <div className="right">
                        <h3
                          className="category-title"
                          style={{
                            border: '2px solid #75AE43',
                            textAlign: 'center',
                            background: '#75AE43',
                            color: `${style?.cate_style?.color}`,
                            fontSize: `${style?.cate_style?.fontSize}`,
                            fontFamily: `${style?.cate_style?.fontFamily}`,
                          }}
                        >
                          {unhideCategory?.[2]?.category}
                        </h3>
                        {unhideCategory?.[2]?.details?.map((val, valIndex) => (
                          <div className="item1">
                            <div className="price-style">
                              {unhideCategory?.[2]?.pricing[valIndex]?.p1 !== ''
                                && (
                                  <p
                                    style={{
                                      color: `${style?.price_style?.color}`,
                                      fontSize: `${style?.price_style?.fontSize}`,
                                      fontFamily: `${style?.price_style?.fontFamily}`,
                                    }}
                                  >
                                    {unhideCategory?.[2]?.pricing[valIndex]?.p1}
                                  </p>
                                )}
                            </div>
                            <div className="topic">
                              <h3
                                style={{
                                  color: `${style?.item_style?.color}`,
                                  fontSize: `${style?.item_style?.fontSize}`,
                                  fontFamily: `${style?.item_style?.fontFamily}`,
                                }}
                              >
                                {val?.name}
                              </h3>
                              <p
                                className="mobileView"
                                style={{
                                  color: `${style?.desc_style?.color}`,
                                  fontSize: `${style?.desc_style?.fontSize}`,
                                  fontFamily: `${style?.desc_style?.fontFamily}`,
                                }}
                              >
                                {val?.desc}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
HtmlTemplateTen.propTypes = {
  templateData: PropTypes.string.isRequired,
};
export default HtmlTemplateTen;
