/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toPng } from 'html-to-image';
import cookies from 'js-cookie';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
// import Button from '../../../../shared/component/Button';
import ModalBox from '../../../../shared/component/ModalBox';
import TemplateContentUpload from '../TemplateContentUpload';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import FileFormat from '../FileFormat';
import { AlataFont } from '../../../../assets/fonts/base64Fonts';
import PopupBox from '../ContentUploadConfirmation';

function AirpodsTemplate({ templateData }) {
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState(false);
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [disable, setDisable] = useState(true);
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguageCode = cookies?.get('i18next') || 'en';
  const htmlRef = useRef(null);

  const cssStyles = `
  @font-face {
    font-family: 'Alata';
    src: url('data:font/truetype;charset=utf-8;base64,${AlataFont}') format('truetype');
  }
    body {
    display: flex;
    justify-content: center;
    align-items:center;
    margin: 0px;
  }
.airpods-template {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 50px;
}

.item-image {
  width: 35%;
  height: 40%;
}
.left-side {
  width: 45%;
  display: flex;
  align-items: center;
  gap: 10%;
}

.item-image img {
    width: 100%;
    height: 100%;
}

.price-wrapper {
    padding-bottom: 30px;
}
.price-wrapper-en {
  margin-left: 20px;
}

.item-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media screen and (min-width: 1920px) {
.title {
    font-size: 50px !important;
}

.subtitle {
    font-size: 40px !important;
}

.description {
    font-size: 40px !important;
}
}

@media screen and (max-width: 1550px) {
.title {
    font-size: 40px !important;
}

.subtitle {
    font-size: 30px !important;
}

.description {
    font-size: 30px !important;
}
}

@media screen and (max-width: 1024px) {
.title {
    font-size: 30px !important;
}

.subtitle {
    font-size: 25px !important;
}

.description {
    font-size: 25px !important;
}
}

@media screen and (max-width: 768px) {
.title {
    font-size: 28px !important;
}

.subtitle {
    font-size: 22px !important;
}

.description {
    font-size: 22px !important;
}
}

@media screen and (max-width: 480px) {
.title {
    font-size: 25px !important;
}

.subtitle {
    font-size: 20px !important;
}

.description {
    font-size: 20px !important;
}
}
`;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = async () => {
    toPng(htmlRef?.current, { cacheBust: true })
      .then((dataUrl) => {
        setBase64(dataUrl);
        /* converted to base64 format */
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (htmlRef.current) {
      const content = htmlRef.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link
                href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
                rel="stylesheet"
              />           
              <style>${cssStyles}</style>
          </head>
          <body>${content}</body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used settimeout the page was fully rendered and then called the api so wait for the response */
      setTimeout(() => {
        convertBase64();
      }, 1000);
      convertHtml();
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        setNotification={setNotification}
        fileType={fileType}
        htmlContent={htmlContent}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };
  const contentDetails = data?.[0]?.template_contents?.items?.[0]?.details;
  const styles = data?.[0]?.styles?.details;
  const backgroundColor = data?.[0]?.styles?.backgroundColor;

  /* We are using two font families because the Alata Google font family was not working for conversion to base64.
   Therefore, we have included the converted base64 encoded file. */

  return (
    <div>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      <div className="header-height" />
      <div className="template-container-11">
        <div className="inner-container">
          <div
            ref={htmlRef}
            className="airpods-template"
            style={{
              background: `${backgroundColor}`,
              height: '100vh',
            }}
          >
            <head>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link
                href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
                rel="stylesheet"
              />
            </head>
            <style>
              {`
            @font-face {
              font-family: 'Alata';
              src: url('data:font/truetype;charset=utf-8;base64,${AlataFont}') format('truetype');
            }
              `}
            </style>
            <div className="left-side">
              <div className="item-image">
                <img
                  src={contentDetails?.[0]?.image}
                  alt=""
                />
              </div>
              <div
                className="price-wrapper"
                style={{
                  marginLeft: currentLanguageCode === 'en' ? 'price-wrapper-en' : 'price-wrapper-ar',
                }}
              >
                {contentDetails?.[0]?.mrp_price
                  && (
                    <span style={{
                      fontSize: `${styles?.[3]?.fontSize}`,
                      color: `${styles?.[3]?.color}`,
                      fontFamily: `${styles?.[3]?.fontFamily}`,
                      margin: '0',
                      marginBottom: '10px',
                    }}
                    >
                      Mrp {' '}
                      <span style={{ textDecoration: 'line-through' }}>{contentDetails?.[0]?.mrp_price}</span>
                    </span>
                  )}
                {contentDetails?.[0]?.offer_price
                  && (
                    <p style={{
                      fontSize: `${styles?.[4]?.fontSize}`,
                      color: `${styles?.[4]?.color}`,
                      fontFamily: `${styles?.[4]?.fontFamily}`,
                      fontWeight: '600',
                      margin: '0px',
                    }}
                    >
                      Off {contentDetails?.[0]?.offer_price}
                    </p>
                  )}
              </div>
            </div>
            <div className="item-list">
              <span
                className="title"
                style={{
                  fontSize: `${styles?.[0]?.fontSize}`,
                  color: `${styles?.[0]?.color}`,
                  fontFamily: `${styles?.[0]?.fontFamily}`,
                }}
              >
                {contentDetails?.[0]?.name}
              </span>
              <span
                className="subtitle"
                style={{
                  fontSize: `${styles?.[1]?.fontSize}`,
                  color: `${styles?.[1]?.color}`,
                  fontFamily: `${styles?.[1]?.fontFamily}`,
                  fontWeight: '600',
                }}
              >
                {contentDetails?.[0]?.tag}
              </span>
              <span
                className="description"
                style={{
                  fontSize: `${styles?.[2]?.fontSize}`,
                  color: `${styles?.[2]?.color}`,
                  fontFamily: `${styles?.[2]?.fontFamily}`,
                }}
              >
                {contentDetails?.[0]?.description}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AirpodsTemplate.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default AirpodsTemplate;
