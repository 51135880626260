/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ProgressBar from '../../../shared/component/Progressbar';
import schedule from '../../../utils/api/schedule';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function ContentRotation({ setOpen, closeModal, rotationValue }) {
  const dispatch = useDispatch();
  const [content, setContent] = useState();
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(false);
  const handleContentRotation = async () => {
    try {
      const result = [];

      for (let i = 0; i < rotationValue.length; i++) {
        const rotationContent = rotationValue[i];
        const requestBody = {
          contentId: rotationContent?.contentId,
          rotation_angle: rotationContent?.rotation,
        };
        setContent(rotationContent?.content_name);
        setTimeout(() => {
          setTimer(true);
        }, 10000);
        try {
          const response = await schedule.contentRotationApi(requestBody);

          if (response?.data) {
            setContent(response.data.content_name);
            setCount((prev) => prev + 1);
            result.push(response.data.content_name);
          }
        } catch (apiError) {
          console.error(`Error processing content ${rotationContent?.content_name}:`, apiError);
          dispatch(setErrorNotification(apiError?.response?.data || 'Failed to rotate content'));
        }
      }
      setTimeout(() => {
        setOpen(false);
        closeModal();
      }, 1000);
      return result;
    } catch (errors) {
      setOpen(false);
      closeModal();
      dispatch(setErrorNotification(errors?.response?.data));
      return [];
    }
  };

  useEffect(() => {
    handleContentRotation();
  }, []);
  return (
    <div className="content-rotation-main-window">
      <div className="content-rotation-title">
        <span className="rotate-title">
          Video rotation is currently saving. Please keep this window open until it&#39;s finished
        </span>
      </div>
      <div className="rotation-upload-bar">
        <ProgressBar
          contentName={content}
          current={count}
          total={rotationValue ? rotationValue.length : 0}
          color="#30A84B"
        />
      </div>
      {timer && (
        <div className="content-rotation-message-info">
          Your update is processing. It may take a moment longer than usual if your file is large.
        </div>
      )}
    </div>
  );
}
ContentRotation.propTypes = {
  setOpen: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  rotationValue: PropTypes.arrayOf(
    PropTypes.shape({
      contentId: PropTypes.string.isRequired,
      content_name: PropTypes.string.isRequired,
      rotation: PropTypes.number.isRequired,
    }),
  ).isRequired,
};
export default ContentRotation;
