/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toPng } from 'html-to-image';
import { useTranslation } from 'react-i18next';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import ModalBox from '../../../../shared/component/ModalBox';
import TemplateContentUpload from '../TemplateContentUpload';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import PopupBox from '../ContentUploadConfirmation';
import FileFormat from '../FileFormat';

function HtmlTemplateTwo({ templateData }) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(false);
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const [disable, setDisable] = useState(true);
  const htmlRef = useRef(null);
  const { t } = useTranslation();

  const cssStyles = `
        body {
            margin: 0;
            padding: 0;
        }

        span {
            font-size: 15px !important;
        }

        .template-container-2 {
            background-color: #f9c92b;
            height: 100%;
        }

        .template-container-2 .ff-template-contents {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .template-container-2 .ff-template-contents .title {
            text-align: center;
            margin: 0 auto;
        }

        .template-container-2 .ff-template-contents .title h2 {
            text-transform: uppercase;
            margin-bottom:
                0.25rem;
            margin-top: 0;
        }

        .template-container-2 .ff-template-contents .title span {
            font-weight: 500;
            font-size: 1rem;
        }

        .template-container-2 .ff-template-contents .menu-inner-card {
            background-color: #38383a;
            width: 90%;
            height: 90%;
            margin: 1rem auto 0 auto;
            position: relative;
            padding-right: 1rem;
            padding-left: 1rem;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .menu-title {
            text-align: center;
            margin-top: 1rem;
            height: 10%;
            display: flex;
            justify-content:
                space-between;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .menu-title h1 {
            padding-top: 1rem;
            margin: 0px;
            font-size: 30px !important;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .ff-icon {
            width: 5vw;
            display:
                block;
            position: relative;
            top: 10px;
            left: -15px;
            padding-left: 0.5rem;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .tomato-icon {
            display: block;
            width: 5vw;
            margin-top: 2%;
            transform: rotate(-75deg);
        }

        .template-container-2 .ff-template-contents .menu-inner-card .tomato-right-icon {
            display: block;
            width: 5vw;
            position: relative;
            transform: rotate(-67deg);
        }

        .template-container-2 .ff-template-contents .menu-inner-card .tomato-right-icon-2 {
            display: block;
            width: 5vw;
            margin-top: 2%;
            transform: rotate(8deg);
        }

        .template-container-2 .ff-template-contents .menu-inner-card .menu {
            display: flex;
            flex-wrap: wrap;
            color: #fff;
            margin-top: 1%;
            height: 70%;
            align-content: space-around;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .menu .item {
            background-color: transparent;
            padding: 1%;
            box-shadow: none;
            flex:
                1 1 calc(50% - 1rem);
        }

        .template-container-2 .ff-template-contents .menu-inner-card .menu .item .ff-menu-wrapper {
            display: flex;
            justify-content: space-between;
            font-size: 15px !important;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .menu .item .ff-menu-container {
            margin-bottom: 1%;
            color: white;
            height: 15%;
            font-size: 15px !important;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .menu .item .menu-wrapper .ff-menu-item {
            font-weight: 700;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 15px !important;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .menu .item .menu-wrapper .ff-menu-price {
            font-weight:
                600;
            margin-top: 15px;
            margin-left: 10px;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .menu .item .ff-menu-container .menu-description {
            width: 50%;
            margin: 0;
            margin-right: 1rem;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px !important;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .right-image-wrapper {
            display:
                flex;
            align-items: center;
            justify-content: center;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .right-image-wrapper .french-fries-icon {
            display: block;
            width: 15vw;
            z-index: 1;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .right-image-wrapper .fruit-bare {
            display: block;
            position:
                absolute;
            left: 215px;
            z-index: 2;
            top: 160px;
            width: 5vw;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .right-image-wrapper .tomato-soup {
            display: block;
            position: absolute;
            z-index: 2;
            left: 380px;
            top: 160px;
            width: 5vw;
        }

        .template-container-2 .ff-template-contents .menu-inner-card .right-image-wrapper .tomato-soup-2 {
            display: block;
            position: absolute;
            z-index: 2;
            right: 220px;
            top: 160px;
            width: 5vw;
        }

        @media(max-width: 1024px) {
            span{
                font-size: 10px !important;
            }
            .template-container-2 .ff-template-contents .menu-inner-card .ff-icon {
                width: 3vw;
            }
            .template-container-2 .ff-template-contents .menu-inner-card .tomato-right-icon {
                width: 3vw;
            }
            .template-container-2 .ff-template-contents .menu-inner-card .tomato-right-icon-2 {
                width: 3vw;
            }
            .template-container-2 .ff-template-contents .menu-inner-card .right-image-wrapper .french-fries-icon {
                width: 10vw;
            }
            .template-container-2 .ff-template-contents .menu-inner-card .right-image-wrapper .fruit-bare {
                width: 3vw;
            }
            .template-container-2 .ff-template-contents .menu-inner-card .right-image-wrapper .tomato-soup {
                width: 3vw;
            }
            .template-container-2 .ff-template-contents .menu-inner-card .right-image-wrapper .tomato-soup-2 {
                width: 3vw;
            }
            .template-container-2 .ff-template-contents .menu-inner-card .menu .item .menu-wrapper .ff-menu-item {
                font-size: 10px !important;
            }
  .template-container-2 .ff-template-contents .menu-inner-card .menu .item .ff-menu-container .menu-description {
                font-size: 10px !important;
            }
        }
`;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = async () => {
    toPng(htmlRef?.current, { cacheBust: true })
      .then((dataUrl) => {
        setBase64(dataUrl);
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (htmlRef.current) {
      const content = htmlRef.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link 
            href="https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100..900&display=swap" 
            rel="stylesheet" />
            <link 
            href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" 
            rel="stylesheet" />
            <style>${cssStyles}</style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };
  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData, notification, openContentUploadConfirmation]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used settimeout the page was fully rendered and then called the api so wait for the response */
      setTimeout(() => {
        convertBase64();
      }, 1500);
      convertHtml();
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        setNotification={setNotification}
        fileType={fileType}
        htmlContent={htmlContent}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };

  const templateContent = data?.[0]?.template_contents?.items;
  const styleRef = data?.[0]?.styles;
  const unhideCategory = templateContent?.filter((filterHide) => !filterHide?.hide);
  return (
    <div style={{ backgroundColor: 'white' }}>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />

      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      <div className="header-height" />
      {data && data?.map((item) => (
        <div className="template-container-2" ref={htmlRef}>
          <div className="ff-template-contents">
            <div className="menu-inner-card">
              <div className="menu-title mt-4">
                <div style={{ display: 'flex', gap: '8%', width: '30%' }}>
                  {item?.template_images?.[0]?.image !== null
                    && (
                      <img
                        src={item?.template_images?.[0]?.image}
                        alt=""
                        className="ff-icon ps-2"
                      />
                    )}
                  {item?.template_images?.[1]?.image !== null
                    && (
                      <img
                        src={item?.template_images?.[1]?.image}
                        alt=""
                        className="tomato-icon"
                      />
                    )}
                </div>
                <h1
                  style={{
                    color: `${styleRef?.title_style?.color}`,
                    fontSize: `${styleRef?.title_style?.fontSize}`,
                    fontFamily: `${styleRef?.title_style?.fontFamily}`,
                    fontWeight: '800',
                    width: '35%',
                    wordWrap: 'break-word',
                  }}
                >
                  {item?.template_title}
                </h1>
                <div
                  style={{ display: 'flex', marginTop: '30px', width: '30%' }}
                >
                  {item?.template_images?.[1]?.image !== null
                    && (
                      <img
                        src={item?.template_images?.[1]?.image}
                        alt=""
                        className="tomato-right-icon"
                      />
                    )}
                  {item?.template_images?.[1]?.image !== null
                    && (
                      <img
                        src={item?.template_images?.[1]?.image}
                        alt=""
                        className="tomato-right-icon-2"
                      />
                    )}
                </div>
              </div>
              <div className="menu">
                <div className="item">
                  {unhideCategory[0]?.details?.slice(0, 5)?.map((detail, dIndex) => (
                    <div key={`card_${detail?.id}`} className="ff-menu-container">
                      <div className="ff-menu-wrapper">
                        <span
                          className="ff-menu-item"
                          style={{
                            color: `${styleRef.item_style.color}`,
                            fontSize: `${styleRef.item_style.fontSize}`,
                            fontFamily: `${styleRef.item_style.fontFamily}`,
                          }}
                        >
                          {detail?.name}
                        </span>
                        <span
                          className="ff-menu-price"
                          style={{
                            color: `${styleRef?.price_style?.color}`,
                            fontSize: `${styleRef?.price_style?.fontSize}`,
                            fontFamily: `${styleRef?.price_style?.fontFamily}`,
                          }}
                        >
                          {unhideCategory?.[0]?.pricing?.[dIndex]?.p1}
                        </span>
                      </div>
                      <p
                        className="menu-description"
                        style={{
                          color: `${styleRef?.desc_style?.color}`,
                          fontSize: `${styleRef?.desc_style?.fontSize}`,
                          fontFamily: `${styleRef?.desc_style?.fontFamily}`,
                        }}
                      >
                        {detail?.desc}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="item">
                  {unhideCategory?.[0]?.details?.slice(5, 11)?.map((detail, i) => (
                    <div key={detail.id} className="ff-menu-container">
                      <div className="ff-menu-wrapper">
                        <span
                          className="ff-menu-item"
                          style={{
                            color: `${styleRef?.item_style?.color}`,
                            fontSize: `${styleRef?.item_style?.fontSize}`,
                            fontFamily: `${styleRef?.item_style?.fontFamily}`,
                          }}
                        >
                          {detail?.name}
                        </span>
                        <span
                          className="ff-menu-price"
                          style={{
                            color: `${styleRef?.price_style?.color}`,
                            fontSize: `${styleRef?.price_style?.fontSize}`,
                            fontFamily: `${styleRef?.price_style?.fontFamily}`,
                          }}
                        >
                          {unhideCategory?.[0]?.pricing?.[i + 5]?.p1}
                        </span>
                      </div>
                      <p
                        className="menu-description"
                        style={{
                          color: `${styleRef?.desc_style?.color}`,
                          fontSize: `${styleRef?.desc_style?.fontSize}`,
                          fontFamily: `${styleRef?.desc_style?.fontFamily}`,
                        }}
                      >
                        {detail?.desc}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="right-image-wrapper">
                {item?.template_images?.[2]?.image !== null
                  && (
                    <img
                      src={item?.template_images?.[2]?.image}
                      alt=""
                      className="french-fries-icon"
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      ))}

    </div>
  );
}

HtmlTemplateTwo.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default HtmlTemplateTwo;
