/* eslint no-underscore-dangle: 0 */
/* eslint max-len: 0 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';

import DataTable from '../Table';

import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import popSchedule from '../../utils/api/pop-schedule';
import TopContent from '../../shared/component/TopContent';
import ModalBox from '../../shared/component/ModalBox';
import ViewDetails from './components/ViewDetails';
import {
  changeDateTimeSecondsFormat,
  checkPermission,
  convertSecondsToHoursAndMinutes,
  statusColor,
} from '../../utils/helpers';

import {
  fetchPOPScheduleList,
  fetchSinglePopUpdateList,
  setFilter,
  setTableFilter,
} from '../../redux/slices/POPScheduleSlice';
import exportCSV from '../Global/exportCSV';
import Button from '../../shared/component/Button';
import height from '../../utils/size-variables';
import { SmallRefreshIcon, ViewIcon } from '../../shared/component/svgIcon';

function POPAnalytics() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    popData,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilter,
    totalDataCount,
  } = useSelector((state) => state.popSchedule);

  const [createPopDetailsModal, setCreatePopDetailsModal] = useState({
    title: '',
    content: 0,
  });
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [loading, setLoading] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    orientation: false,
  });

  const getPop = (counts, filters = {}) => {
    if (status === 'succeeded') {
      dispatch(fetchPOPScheduleList({ page: counts, limit: 20, ...filters }));
    }
  };
  const handleScroll = () => {
    getPop(pageCount + 1, filter);
  };

  const closeModal = () => {
    setCreatePopDetailsModal({
      title: '',
      content: 0,
    });
  };

  const onButtonClick = (popScheduleDetails) => {
    setCreatePopDetailsModal({
      title: popScheduleDetails?.scheduleName,
      content: <ViewDetails details={popScheduleDetails?.sequentialData} />,
    });
  };

  // Function to handle export CSV file
  const exportData = () => {
    const columnHeaders = [
      'deviceName', 'scheduleName', 'scheduleStartDate', 'scheduleEndDate', 'deviceOff',
      'outOfSync', 'matchingSchedule', 'completionPercentage', 'ReportStatus'];
    // API call for export CSV
    popSchedule.exportToCsv()
      .then((res) => {
        const rows = res?.data.map((val) => {
          const temp = {
            ...val,
            deviceName: val?.device_name,
            scheduleName: val?.schedule_name,
            scheduleStartDate: changeDateTimeSecondsFormat(val?.schedule_start),
            scheduleEndDate: changeDateTimeSecondsFormat(val?.schedule_end),
            deviceOff: convertSecondsToHoursAndMinutes(val?.total_off_time),
            outOfSync: convertSecondsToHoursAndMinutes(val?.out_Of_sync),
            matchingSchedule: convertSecondsToHoursAndMinutes(val?.matching_schedule),
            ReportStatus: val?.status,
            completionPercentage: `${val.percentage}%`,
          };
          return temp;
        });
        exportCSV(columnHeaders, rows);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };
  const handleRefreshClick = (data) => {
    if (data) {
      setLoading(data);
      dispatch(fetchSinglePopUpdateList({ id: data }));
      setTimeout(() => {
        setLoading(null);
      }, 1000);
    }
  };
  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('deviceName'),
      accessorKey: 'device_name',
      enableSorting: false,
    },
    {
      header: t('scheduleName'),
      accessorKey: 'schedule_name',
      enableSorting: false,
    },
    {
      header: t('scheduleStarts'),
      accessorKey: 'scheduleStarts',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row }) => row.original.scheduleStartDate,
      size: 200,
    },
    {
      header: t('scheduleEnds'),
      accessorKey: 'scheduleEnds',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row }) => row.original.scheduleEndDate,
      size: 200,
    },
    {
      header: t('deviceOff'),
      accessorKey: 'deviceOff',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('outOfSync'),
      accessorKey: 'outOfSync',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('matchingSchedule'),
      accessorKey: 'matchingSchedule',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('completionPercentage'),
      accessorKey: 'completionPercentage',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: 'Report Status',
      accessorKey: 'status',
      enableColumnFilter: false,
      enableSorting: false,
      Header: () => (
        <div className="pop-status">
          <span>Report Status</span>
          <Tooltip title="Device status updates every 10 minutes. For instant updates, click 'Reload' by the 'Pending' status. Wait for the new status.">
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
      size: 200,
      Cell: ({ cell }) => {
        const rawStatus = cell.getValue();
        let statusValue;
        if (rawStatus === 'Completed') {
          statusValue = 'completed';
        } else if (rawStatus === 'Pending') {
          statusValue = 'inProgress';
        }
        return (
          <Box
            component="span"
            sx={{
              backgroundColor: statusColor(statusValue).backgroundColor,
              borderRadius: '0.25rem',
              color: statusColor(statusValue).color,
              p: '0.25rem',
            }}
          >
            {rawStatus === 'Pending' ? (
              <div
                className="pop-status-container"
              >
                <span>{rawStatus}</span>
                <span
                  role="presentation"
                  className="pop-loader"
                  onClick={() => handleRefreshClick(cell?.row?.original?._id)}
                >
                  <span className={loading === cell?.row?.original?._id ? 'refresh-loading' : 'refresh-icon'}>
                    <SmallRefreshIcon />
                  </span>
                </span>
              </div>
            ) : (
              rawStatus
            )}
          </Box>
        );
      },
    },
    {
      header: 'Action',
      accessorKey: 'view',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      Cell: ({ row }) => (
        <Button
          id="view-detail"
          label={t('viewDetail')}
          click={() => onButtonClick(row?.original)}
          classes="default-button button-outline-success btn-sx btn-text-success"
        />
      ),
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getPop(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getPop(1, {});
    }
  }, []);

  return (
    <div className="content">
      <div className="main-content">
        <TopContent
          label={t('popDevice')}
          buttonClass="success-button mycontent-createbutton"
          button={false}
          click={() => { }}
        />
        <ModalBox
          status={createPopDetailsModal.content !== 0}
          closeModal={closeModal}
          modalView={createPopDetailsModal}
          notification={false}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area  mydata-table">
              <DataTable
                className="my-data-table"
                header={columns}
                value={popData}
                status={status}
                onFilterOrSortingChange={onFilterOrSortingChange}
                reduxColumnFiltersSorting={tableFilter}
                isFirstRender={isFirstRender}
                totalPageCount={totalPageCount}
                pageCount={pageCount}
                scrollData={handleScroll}
                totalDataCount={totalDataCount}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                height={height.onlyTableHeight}
                exportButton={checkPermission('exportPOPDevice')}
                exportData={exportData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

POPAnalytics.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        _id: PropTypes.string,
      }),
    }),
    getValue: PropTypes.func.isRequired,
  }),
};
POPAnalytics.defaultProps = {
  row: {},
  cell: null,
};

export default POPAnalytics;
