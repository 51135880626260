/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toPng } from 'html-to-image';
import cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import ModalBox from '../../../../shared/component/ModalBox';
import TemplateContentUpload from '../TemplateContentUpload';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import FileFormat from '../FileFormat';
import PopupBox from '../ContentUploadConfirmation';

function DmartTemplate({ templateData }) {
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState(false);
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [disable, setDisable] = useState(true);
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguageCode = cookies?.get('i18next') || 'en';
  const htmlRef = useRef(null);
  const cssStyles = `
  body {
  margin: 0px;
  padding: 0px;
  width: 100vw;
  }

.d-mart-template {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.logo-image {
  position: absolute;
  top: 20px;
}

.logo-image img {
  width: 5vw;
}

.item-list {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.items {
  display: flex;
  align-items: center;
  width: 15%;
}

.item-image {
  width: 100%;
  height: 100%;
}

.item-list .items span {
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-family: Inter;
}

@media screen and (min-width: 1550px) {
  .item-list .items span {
    font-size: 20px;
  }
  .items{
    width: 10%;
  }
}
`;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = async () => {
    toPng(htmlRef?.current, { cacheBust: true })
      .then((dataUrl) => {
        setBase64(dataUrl);
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (htmlRef.current) {
      const content = htmlRef.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
           <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet" />
            <style>${cssStyles}</style>
          </head>
          <body>${content}</body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used settimeout the page was fully rendered and then called the api so wait for the response */
      setTimeout(() => {
        convertBase64();
      }, 1000);
      convertHtml();
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        htmlContent={htmlContent}
        setNotification={setNotification}
        fileType={fileType}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };

  const contentDetails = data?.[0]?.template_contents?.items?.[0]?.details;
  const styles = data?.[0]?.styles?.details;
  const logoImage = data?.[0]?.template_images?.[0]?.image;
  const backgroundColor = data?.[0]?.styles?.backgroundColor;

  return (
    <div>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />

      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      <div className="header-height" />
      <div className="template-container-6">
        <div className="inner-container">
          <div
            className="d-mart-template"
            ref={htmlRef}
            style={{
              background: `${backgroundColor}`,
            }}
          >
            <Helmet>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet" />
            </Helmet>
            {logoImage
              && (
                <div
                  className="logo-image"
                  style={{
                    left: currentLanguageCode === 'en' ? '20px' : '',
                    right: currentLanguageCode === 'ar' ? '20px' : '',
                  }}
                >
                  <img src={logoImage} alt="" />
                </div>
              )}
            <div className="item-list">
              {contentDetails?.map((item, index) => (
                <div
                  key={item?.id}
                  className="items"
                >
                  {item?.image
                    && (
                      <div>
                        <img src={item?.image} alt="" className="item-image" />
                      </div>
                    )}
                  <span
                    className="item-value"
                    style={{
                      fontSize: `${styles?.[0]?.fontSize}`,
                      color: `${styles?.[0]?.color}`,
                      fontFamily: `${styles?.[0]?.fontFamily}`,
                      marginLeft: index === 4 ? '10px' : '',
                    }}
                  >
                    {item?.price}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DmartTemplate.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default DmartTemplate;
