import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currencySymbol } from '../../../utils/helpers';
import { RadioButton } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { getPaymentType } from '../../../redux/slices/SubscriptionSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import aiInstance from '../../../utils/api/ai-instance';

function RenewalPaymentModal(props) {
  const {
    backModal,
    currency,
    submitPayment,
    closeModal,
    renewalInfo,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { paymentType } = useSelector((state) => state?.subscription);
  const [paymentMode, setPaymentMode] = useState(paymentType);
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);

  const submit = () => {
    if (paymentMode === 'online') {
      setDisable(true);
      setUploading(true);
      const req = {
        instance_total_hours: renewalInfo?.instance_hours,
        ai_instance_id: renewalInfo?.instance_id,
        payment_mode: paymentMode,
      };
      aiInstance.renewalInstanceOnline(req)
        .then((res) => {
          setUploading(false);
          setDisable(false);
          if (res?.data?.paymentUrl) {
            const newWindow = window.open('', '_blank');
            console.log(newWindow, 'dfkdbhxj');
            if (!newWindow) {
              dispatch(
                setErrorNotification(
                  { message: 'Popup blocked! Please allow popups in your browser settings.' },
                ),
              );
            }
            newWindow.location.href = res?.data?.paymentUrl; // Update the blank window with the URL
            newWindow.focus();
            closeModal(false);
          } else {
            closeModal(false);
            dispatch(
              setErrorNotification(
                { message: 'There\'s a temporary issue with our payment system. Please try again later.' },
              ),
            );
          }
        })
        .catch((error) => {
          setUploading(false);
          setDisable(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    } else {
      submitPayment(renewalInfo);
      dispatch(getPaymentType(paymentMode));
    }
  };

  useEffect(() => {
    if (paymentMode) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [paymentMode]);

  return (
    <div className="ai-instance-summary">
      <div className="device-change-content">
        <div className="sass-summary">
          <table>
            <tr>
              <td colSpan="5">
                <div className="table-header">
                  <span>{t('aiAnalyticsSummary')}</span>
                  <div className="success-link" onClick={backModal} role="presentation">{t('editInstance')}</div>
                </div>
              </td>
            </tr>
            <tr>
              <th>{t('description')}</th>
              <th>{t('unitPrice')}</th>
              <th>{t('quantity')}</th>
              <th>{t('totalPrice')}</th>
              <th>{t('validFrom')}</th>
            </tr>
            <tr>
              <td>{t('aiRenewal')}</td>
              <td>{currencySymbol(currency, renewalInfo?.totalPrice)}</td>
              <td>{1 > 0 && '+ '}{renewalInfo?.instanceCount}</td>
              <td>{currencySymbol(currency, renewalInfo?.totalPrice)}</td>
              <td>{renewalInfo?.validFrom}</td>
            </tr>
          </table>
          <div className="payment-mode">
            <h2 className="payment-title">{t('paymentMode')}</h2>
            <div className="form">
              <RadioButton
                checked={paymentMode === 'online'}
                value="online"
                label={t('online')}
                onChange={(e) => setPaymentMode(e)}
                id="online"
              />
              {/* future offline purchase so this commented */}
              {/* <RadioButton
                checked={paymentMode === 'offline'}
                value="offline"
                label={t('offline')}
                onChange={(e) => setPaymentMode(e)}
                id="offline"
              /> */}
              <div className="form-button-group">
                <div className="form-button">
                  <Button
                    label={t('back')}
                    click={backModal}
                    classes="default-button"
                  />
                </div>
                <div className="form-button">
                  <Button
                    label={!uploading ? t('continue') : t('loading')}
                    click={submit}
                    classes={uploading ? 'success-button loading-btn' : 'success-button'}
                    disabled={disable}
                    loading={uploading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

RenewalPaymentModal.propTypes = {
  backModal: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  submitPayment: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  renewalInfo: PropTypes.string.isRequired,
};

export default RenewalPaymentModal;
