/* eslint-disable no-plusplus */
/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';
import PropTypes from 'prop-types';
import { CircularProgress, Skeleton } from '@mui/material';

import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import InputTags from '../../../shared/component/form/InputTags';
import myContent from '../../../utils/api/my-content';

import { createNewContent } from '../../../redux/slices/MyContentSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import SelectBox from '../../../shared/component/form/SelectBox';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';

import {
  RightArrow,
  UrlLinkIcon,
  VolumeMuteIcon,
  VolumeUnmuteIcon,
} from '../../../shared/component/svgIcon';
import SliderComponent from '../../../shared/component/form/SliderInput';
import {
  checkFeatures,
  checkPermission,
  contentUploadImageResize,
  extractMediaDimensions,
} from '../../../utils/helpers';
import {
  descriptionValidation,
  integerValidation,
  nameValidation,
  tagValidation,
  urlValidation,
  youtubeUrlValidation,
} from '../../../shared/component/form/Validation';
import MultipleFileView from './MultiFileView';
// import CustomSelectBoxApp from '../../../shared/component/form/CustomSelectBox';
import generateVideoThumbnails from '.';

function ContentUpload(props) {
  const { t } = useTranslation();
  const orientation = [
    {
      id: 1,
      label: t('portrait'),
    },
    {
      id: 2,
      label: t('landscape'),
    },
  ];
  const transitions = [
    {
      id: 1,
      label: t('fadeIn'),
    },
    {
      id: 2,
      label: t('fadeOut'),
    },
    {
      id: 3,
      label: t('slideInUp'),
    },
    {
      id: 4,
      label: t('slideInDown'),
    },
    {
      id: 5,
      label: t('slideInRight'),
    },
    {
      id: 6,
      label: t('slideInLeft'),
    },
    {
      id: 7,
      label: t('slideOutUp'),
    },
    {
      id: 8,
      label: t('slideOutDown'),
    },
    {
      id: 9,
      label: t('slideOutLeft'),
    },
    {
      id: 10,
      label: t('slideOutRight'),
    },
  ];

  const dispatch = useDispatch();

  const [disable, setDisable] = useState(true);
  const [uploading, setUploading] = useState();
  const [uploadMessage, setUploadMessage] = useState('');
  const [notification, setNotification] = useState(false);
  const [value, setValue] = useState({
    ...props?.contentData,
    file_type: props?.contentData?.file_type || props?.contentData?.file?.type,
    duration: props?.contentData?.file_type?.includes('url') ? 25 : 5,
    orientation: t('landscape'),
    start_transition: t('fadeIn'),
    end_transition: t('fadeOut'),
  });
  const [advanceSetting, setAdvanceSetting] = useState();
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [triggerContentUploaded, setTriggerContentUpload] = useState(false);
  // const [audiencePreference, setAudiencePreference] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [urlError, setUrlError] = useState('');
  const [thumbnailImageCount, setThumbnailImageCount] = useState(0);
  // Handle multiple types of content upload
  const [multiContentData, setMultiContentData] = useState(props?.filesWithThumbnail || []);
  const [multiContentDataCheck, setMultiContentDataCheck] = useState({
    imageOrPdfTypeSelected: false,
    videoTypeSelected: false,
  });
  const [multiFileUpload, setMultiFileUpload] = useState({
    uploadingCount: 1,
    errorContents: [],
  });

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };
  const thumbnailArr = [];
  const getThumbnailImage = async (videoFile, name) => {
    setSkeletonLoader(true);
    generateVideoThumbnails(videoFile, 5)
      .then((thumbnailArray) => {
        setSkeletonLoader(false);
        setThumbnails(thumbnailArray?.res);
        const dynamicKey = name;
        const dynamicValue = thumbnailArray?.res?.[0];
        thumbnailArr.push({ image: dynamicValue, name: `${dynamicKey}` });
        setSelectedThumbnail(thumbnailArr);
      }).catch((err) => {
        setSkeletonLoader(false);
        console.error(err);
      });
  };
  const handleFunction = async (name, changeValue, key = 0) => {
    if (name === 'tags' && key === 13) {
      setValue({ ...value, [name]: Array.isArray(changeValue) ? changeValue : [...value.tags, changeValue] });
    } else if (
      (name === 'orientation'
        || name === 'start_transition'
        || name === 'end_transition')
      && changeValue
    ) {
      setValue({ ...value, [name]: changeValue.label });
    } else if (
      name === 'name'
      || name === 'description'
      || name === 'duration'
      || name === 'thumbnail'
      || name === 'volume'
      || name === 'url'
    ) {
      setValue({ ...value, [name]: changeValue });
    }
  };

  function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const uploadSingleContent = async (request) => {
    try {
      const response = await myContent.addContent(request);
      dispatch(createNewContent(response?.data));
      if (props?.contentData?.file_type === 'multi_file' && response?.data?._id) {
        setMultiFileUpload((prevData) => ({
          ...prevData,
          uploadingCount: prevData.uploadingCount + 1,
        }));
      } else if (props?.contentData?.file_type === 'multi_file' && !response?.data?._id) {
        setMultiFileUpload((prevData) => ({
          ...prevData,
          errorContents: [...prevData.errorContents, request?.name],
        }));
      }
    } catch (error) {
      if (props?.contentData?.file_type === 'multi_file') {
        setMultiFileUpload((prevData) => ({
          ...prevData,
          uploadingCount: prevData.uploadingCount + 1,
          errorContents: [...prevData.errorContents, request?.name],
        }));
      }
      if (error?.response?.data) {
        dispatch(setErrorNotification(error.response.data));
      }
    }
  };

  // handle multi-content upload one by one in loop
  let thumbnailCount = 0;
  const handleMediaFileUpload = async () => {
    if (multiContentData) {
      for (let i = 0; i < multiContentData.length; i++) {
        const contentData = multiContentData[i];
        const requestBody = {
          name: contentData?.content_name,
          tags: value?.tags,
          orientation: value?.orientation,
          description: value?.description,
          start_transition: value?.start_transition,
          end_transition: value?.end_transition,
          // ai_recommendation_tags: audiencePreference,
        };
        if (contentData?.file?.type.includes('video')) {
          const thumbnail = selectedThumbnail.filter((data) => data.name === contentData?.content_name);
          const videoDimensions = await extractMediaDimensions(contentData?.file, 'video');
          if (videoDimensions) {
            requestBody.content_resolution = {
              width_in_resolution: videoDimensions.width,
              height_in_resolution: videoDimensions.height,
            };
          }
          requestBody.file = contentData?.file;
          requestBody.volume = value?.volume;
          requestBody.thumbnail = thumbnail?.[0]?.image
            ? {
              px_600x400: dataURLtoFile(
                thumbnail?.[0]?.image,
                `${contentData?.content_name}_600x400`,
              ),
              px_50x50: dataURLtoFile(
                thumbnail?.[0]?.image,
                `${contentData?.content_name}_50x50`,
              ),
            }
            : undefined;
        } else if (contentData?.file?.type.includes('image')) {
          // get the dimension of the image file
          const imageDimensions = await extractMediaDimensions(contentData?.file, 'image');
          if (imageDimensions) {
            requestBody.content_resolution = {
              width_in_resolution: imageDimensions.width,
              height_in_resolution: imageDimensions.height,
            };
          }
          const resizedImages = await contentUploadImageResize(contentData?.file);
          // const resizedImageResolutions = {};

          // // Use Object.entries to loop through the object's own properties
          // Object.entries(resizedImages).forEach(([key, resizedImage]) => {
          //   // Destructure to exclude resizedImageUrl
          //   const { resizedImageUrl, ...rest } = resizedImage;
          //   // Store the remaining properties in the new object
          //   resizedImageResolutions[key] = rest;
          // });
          // requestBody.resized_image_resolutions = resizedImageResolutions;

          requestBody.file = contentData?.file;
          requestBody.duration = value?.duration;
          requestBody.thumbnail = {
            px_600x400: resizedImages?.px_600x400
              && dataURLtoFile(resizedImages?.px_600x400?.resizedImageUrl, contentData?.content_name),
            px_50x50: resizedImages?.px_50x50
              && dataURLtoFile(resizedImages?.px_50x50?.resizedImageUrl, contentData?.content_name),
          };
          // requestBody.content_images = {
          //   px_7680x4320: resizedImages?.px_7680x4320
          //     && dataURLtoFile(resizedImages?.px_7680x4320?.resizedImageUrl, contentData?.content_name),
          //   px_3840x2160: resizedImages?.px_3840x2160
          //     && dataURLtoFile(resizedImages?.px_3840x2160?.resizedImageUrl, contentData?.content_name),
          //   px_2048x1080: resizedImages?.px_2048x1080
          //     && dataURLtoFile(resizedImages?.px_2048x1080?.resizedImageUrl, contentData?.content_name),
          //   px_1920x1080: resizedImages?.px_1920x1080
          //     && dataURLtoFile(resizedImages?.px_1920x1080?.resizedImageUrl, contentData?.content_name),
          //   px_1280x720: resizedImages?.px_1280x720
          //     && dataURLtoFile(resizedImages?.px_1280x720?.resizedImageUrl, contentData?.content_name),
          // };
        } else if (contentData?.file?.type.includes('pdf')) {
          requestBody.file = contentData.file;
          requestBody.duration = value?.duration;
        }

        // Wait for a single content upload
        await uploadSingleContent(requestBody);
      }
    }
  };

  const singleFileUpload = async () => {
    const requestBody = {
      tags: value?.tags,
      name: value?.name,
      orientation: value?.orientation,
      description: value?.description,
      start_transition: value?.start_transition,
      end_transition: value?.end_transition,
      // ai_recommendation_tags: audiencePreference,
    };
    if (value?.file_type === 'url') {
      requestBody.duration = value?.duration;
      requestBody.url = value.url;
      requestBody.file_type = value?.file_type;
    } else if (value?.file_type === 'youtube') {
      requestBody.url = value.url;
      requestBody.file_type = value?.file_type;
    }

    await uploadSingleContent(requestBody);
  };

  const submit = async () => {
    setSubmitButtonClicked(true);
    setDisable(true);
    setUploading(true);
    props?.setUploadingContent(true);

    if ((nameValidation(1, 50, value?.name) || props?.contentData?.file_type === 'multi_file')
      && descriptionValidation(500, value?.description)
      && ((urlValidation(value?.url) || youtubeUrlValidation(value?.url))
        || (value?.file_type !== 'url' && value?.file_type !== 'youtube'))
      && tagValidation(30, 10, value?.tags)
      && ((integerValidation(1, Infinity, value?.duration)
        && multiContentDataCheck?.imageOrPdfTypeSelected)
        || !multiContentDataCheck?.imageOrPdfTypeSelected)) {
      setDisable(true);
      setUploading(true);
      props?.setUploadingContent(true);

      if (props?.contentData?.file_type === 'multi_file') {
        await handleMediaFileUpload();
        setUploading(false);
        props?.setUploadingContent(false);
        setTriggerContentUpload(true);
      } else {
        await singleFileUpload();
        setUploading(false);
        props?.setUploadingContent(false);
        setUploadMessage(t('contentCreatedSuccess'));
        setNotification(true);
        props?.refresh();
        setTimeout(() => {
          setNotification(false);
          setDisable(false);
          props?.close(false);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (triggerContentUploaded) {
      setTriggerContentUpload(false);
      if (multiFileUpload?.errorContents?.length === 0) {
        setUploadMessage(t('Content(s) created successfully!'));
        setNotification(true);
        setTimeout(() => {
          setNotification(false);
          setDisable(false);
          props?.refresh();
          props?.close(false);
        }, 3000);
      } else if (multiFileUpload?.errorContents?.length > 0) {
        setDisable(false);
        props?.refresh();
        const filteredContentData = multiContentData
          ?.filter((content) => multiFileUpload.errorContents.includes(content.content_name));
        setMultiContentData(filteredContentData || []);
      }
      // Set default status for multi-file upload...
      setMultiFileUpload({
        uploadingCount: 1,
        errorContents: [],
      });
    }
  }, [triggerContentUploaded]);

  useEffect(() => {
    const processContentData = async () => {
      if (multiContentData.length > 0) {
        let imageOrPdfTypeSelected = false;
        let videoTypeSelected = false;

        // Process content files using map and Promise.all
        await Promise.all(
          multiContentData.map(async (content) => {
            if (content?.file?.type?.includes('image') || content?.file?.type?.includes('pdf')) {
              imageOrPdfTypeSelected = true;
            }
            if (content?.file?.type?.includes('video')) {
              thumbnailCount += 1;
              videoTypeSelected = true;
              await getThumbnailImage(content?.file, content?.content_name);
              setThumbnailImageCount(thumbnailCount);
            }
          }),
        );

        setMultiContentDataCheck({
          imageOrPdfTypeSelected,
          videoTypeSelected,
        });
      }
    };

    processContentData();
  }, [multiContentData]);

  useEffect(() => {
    const isMultiFileUpload = props?.contentData?.file_type === 'multi_file';
    const isURL = value?.file_type?.includes('url');
    const isYoutube = value?.file_type?.includes('youtube') && value?.url;

    let hasError = false;

    // Check duration validation if file_type includes 'url'
    if (isURL && value?.duration < 25) {
      setUrlError(t('Duration must be at least 25 seconds.'));
      hasError = true;
    } else {
      setUrlError('');
    }

    if ((value.name || isMultiFileUpload)
      && value.orientation
      && value.start_transition
      && value.end_transition
      && (isMultiFileUpload || isYoutube || isURL)
      && ((value.duration > 0
        && multiContentDataCheck?.imageOrPdfTypeSelected)
        || !multiContentDataCheck?.imageOrPdfTypeSelected)
      && !hasError // Ensure no validation errors
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);
  useEffect(() => {
    if (props?.contentData?.file_type === 'multi_file') {
      setMultiContentData(props?.contentData?.filesWithThumbnail);
    }
  }, []);
  useEffect(() => {
    if (selectedThumbnail) {
      // If selectedThumbnail exists, enable the submit button
      setDisable(false);
    } else if (props?.contentData?.filesWithThumbnail) {
      // If filesWithThumbnail exists, check if all items have file as null
      const allFilesNull = props?.contentData?.filesWithThumbnail.every((item) => item?.thumbnail === null);
      if (allFilesNull) {
        setDisable(false); // Enable submit button if all files are null
      } else {
        setDisable(true); // Disable submit button if not all files are null
      }
    } else {
      // Default behavior if there's no selectedThumbnail or filesWithThumbnail
      setDisable(true);
    }
  }, [selectedThumbnail, props?.contentData?.filesWithThumbnail]);
  return (
    <div className="upload-media">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={uploadMessage} /> }}
      />
      <div className="form">
        {(props?.contentData?.file_type === 'multi_file')
          && (
            <MultipleFileView
              multiContentData={multiContentData || []}
              setMultiContentData={setMultiContentData}
            />
          )}
        {(skeletonLoader && props?.contentData?.filesWithThumbnail?.length === 1)
          && (
            <div className="thumbnail-container skeleton-loader">
              <Skeleton variant="rectangular" width="100%" height="100%" />
              <Skeleton variant="rectangular" width="100%" height="100%" />
              <Skeleton variant="rectangular" width="100%" height="100%" />
              <Skeleton variant="rectangular" width="100%" height="100%" />
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>
          )}
        <div className="thumbnail-container">
          {(thumbnails && props?.contentData?.filesWithThumbnail?.length === 1)
            && thumbnails.map((item) => (
              <img
                key={item}
                src={item}
                alt="thumbnail"
                role="presentation"
                onClick={
                  () => setSelectedThumbnail([
                    { name: props?.contentData?.filesWithThumbnail?.[0]?.content_name, image: item },
                  ])
                }
                className={selectedThumbnail?.[0]?.image === item
                  ? 'thumbnail-image selectedThumbnail' : 'thumbnail-image'}
              />
            ))}
        </div>
        {(props?.contentData?.file_type === 'url' || props?.contentData?.file_type === 'youtube')
          && (
            <div className="url-image-preview-container">
              <p>{t('preview')}</p>
              {props?.contentData?.file_type === 'youtube' ? (
                <YouTube
                  videoId={getYouTubeID(value?.url)}
                  opts={opts}
                  className="youtube-video"
                  onReady={(e) => e.target.pauseVideo()}
                />
              ) : (
                <div className="url-image">
                  {value?.url
                    ? (
                      <iframe
                        src={value?.url}
                        title="url"
                      />
                    ) : (
                      <UrlLinkIcon />
                    )}
                </div>
              )}
            </div>
          )}
        {(props?.contentData?.file_type === 'url' || props?.contentData?.file_type === 'youtube')
          && (
            <div>
              <Input
                icon={' '}
                label={t('URL')}
                type="text"
                name="url"
                required
                value={value?.url}
                change={handleFunction}
                placeholder={t('enterUrl')}
                error={(!urlValidation(value?.url) && !youtubeUrlValidation(value?.url))
                  && submitButtonClicked}
                errorMessage={t('urlValidation')}
              />
            </div>
          )}
        <div className="form-fields">
          <div className={props?.contentData?.file_type !== 'multi_file' && 'form-fields-row'}>
            {props?.contentData?.file_type !== 'multi_file'
              && (
                <Input
                  icon={' '}
                  label={t('contentName')}
                  type="text"
                  name="name"
                  required
                  value={value?.name}
                  change={handleFunction}
                  placeholder={t('enterContentName')}
                  error={!nameValidation(1, 50, value?.name) && submitButtonClicked}
                  errorMessage={t('contentNameValidation')}
                />
              )}
            <SelectBox
              label={t('orientation')}
              name="orientation"
              lists={orientation}
              value={value?.orientation}
              onchange={handleFunction}
              placeholder={t('selectOrientation')}
              id="orientation"
            />
          </div>
          <Input
            icon={' '}
            label={t('contentDescription')}
            name="description"
            value={value?.description}
            change={handleFunction}
            placeholder={t('enterContentDescription')}
            error={!descriptionValidation(500, value?.description) && submitButtonClicked}
            errorMessage={t('descriptionValidation')}
          />
          <InputTags
            icon={' '}
            label={t('mediaTags')}
            name="tags"
            addData={handleFunction}
            required={false}
            value={value?.tags}
            placeholder={t('enterMediaTags')}
            error={!tagValidation(30, 10, value?.tags) && submitButtonClicked}
            errorMessage={t('tagsValidation')}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '16px',
            }}
          >
            {/* Triggering the Popup when CustomSelectBox is clicked */}
            {/* <CustomSelectBoxApp setAudiencePreference={setAudiencePreference} /> */}
          </div>
        </div>
        <div className="advance-settings-wrap">
          <div
            role="presentation"
            onClick={() => setAdvanceSetting(!advanceSetting)}
            className="advance-header"
          >
            <h2>{t('advancedSettings')}</h2>
            <span
              style={{
                transform: advanceSetting ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
            >
              <RightArrow />
            </span>
          </div>
          <div
            className="settings-input"
            style={{
              height: advanceSetting ? '185px' : '0px',
            }}
          >
            <div className="single-row">
              <SelectBox
                label={t('startTransition')}
                name="start_transition"
                lists={transitions}
                value={value?.start_transition}
                onchange={handleFunction}
                placeholder={t('selectTransitions')}
                id="start_transitions"
              />
              <SelectBox
                label={t('endTransition')}
                name="end_transition"
                lists={transitions}
                value={value?.end_transition}
                onchange={handleFunction}
                placeholder={t('selectTransitions')}
                id="end_transitions"
              />
            </div>
            <div className="single-row">
              {(!value?.file_type?.includes('youtube')
                && (value?.file_type?.includes('url')
                  || multiContentDataCheck?.imageOrPdfTypeSelected))
                && (
                  <Input
                    icon={' '}
                    label={t('durationSecond')}
                    type="number"
                    name="duration"
                    required
                    value={value?.duration}
                    change={handleFunction}
                    placeholder={t('enterDuration')}
                    error={
                      (!integerValidation(1, Infinity, value?.duration) && submitButtonClicked)
                      || (props?.contentData?.file_type?.includes('url') && value?.duration < 25)
                    }
                    errorMessage={
                      props?.contentData?.file_type?.includes('url') && value?.duration < 25
                        ? urlError
                        : t('durationValidation')
                    }
                  />
                )}
              {(multiContentDataCheck?.videoTypeSelected
                && checkFeatures('content', 'volume_controller')
                && checkPermission('volumeControllerContent'))
                && (
                  <SliderComponent
                    label={t('volume')}
                    name="volume"
                    icon={
                      value?.volume > 0
                        ? <VolumeUnmuteIcon />
                        : <VolumeMuteIcon />
                    }
                    value={value?.volume}
                    changeFunc={handleFunction}
                  />
                )}
            </div>
          </div>
        </div>
        <div className="content-button-group">
          <div
            className="upload-count"
            style={{
              opacity: multiContentData?.length > 0 && uploading ? '1' : '0',
            }}
          >
            <span>
              <CircularProgress size={20} sx={{ color: '#717880' }} />
            </span>
            <p>Uploading {multiFileUpload?.uploadingCount} of {multiContentData?.length}</p>
          </div>
          <div className="button-wrap">
            <div className="form-button">
              <Button
                label={t('back')}
                click={() => props?.close(false)}
                classes="default-button"
                disabled={uploading}
              />
            </div>
            <div className="form-button">
              <Button
                label={uploading ? t('uploading') : t('upload')}
                click={submit}
                classes={uploading ? 'success-button loading-btn' : 'success-button'}
                disabled={disable || (thumbnailImageCount !== selectedThumbnail?.length)}
                loading={uploading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ContentUpload.propTypes = {
  contentData: PropTypes.shape(),
  close: PropTypes.func.isRequired,
  filesWithThumbnail: PropTypes.arrayOf(),
  refresh: PropTypes.func.isRequired,
  setUploadingContent: PropTypes.node.isRequired,
};
ContentUpload.defaultProps = {
  contentData: {},
  filesWithThumbnail: [],
};

export default ContentUpload;
