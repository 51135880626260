import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DotLoader from '../../../shared/component/Loader';

import {
  fetchDeviceActiveLogsList,
  setFilter,
  // setTableFilter,
} from '../../../redux/slices/DeviceActiveLogsSlice';
import DataTable from '../../Table';
import { ViewIcon } from '../../../shared/component/svgIcon';

function ActivityLog(props) {
  const { id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    deviceActiveLogs,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilter,
    totalDataCount,
  } = useSelector((state) => state.deviceActiveLogs);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [modalLoader, setModalLoader] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const getActivityLog = (count, search) => {
    if (status === 'succeeded') {
      dispatch(fetchDeviceActiveLogsList({ page: count, deviceId: id, ...search }));
    }
  };

  const handleScroll = () => {
    getActivityLog(pageCount + 1, filter);
  };
  let datePickerIndex = 0;
  const columns = [
    {
      header: t('timeStamp'),
      Header: () => (
        <div className="schedule-status">
          <span>{t('timeStamp')}</span>
          <Tooltip title={t('timeStampWillBeInUtcFormat')}>
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
      accessorKey: 'timeStamp',
      enableColumnFilter: true,
      enableSorting: false,
      filterVariant: 'date-range',
      filterFn: 'commonFIlterFn',
      size: 200,
      accessorFn: (originalRow) => new Date(originalRow.timeStamp),
      muiFilterTextFieldProps: () => {
        datePickerIndex += 1;
        return {
          placeholder: datePickerIndex === 1 ? t('fromDate') : t('toDate'),
        };
      },
      Cell: ({ row }) => row.original.timestamp,
    },
    {
      header: t('activityType'),
      accessorKey: 'activityType',
      enableSorting: false,
    },
    {
      header: t('scheduleName'),
      accessorKey: 'scheduleName',
      enableSorting: false,
    },
    {
      header: t('scheduleId'),
      accessorKey: 'scheduleId',
      enableSorting: false,
    },
    {
      header: t('contentName'),
      accessorKey: 'contentName',
      enableSorting: false,
    },
    {
      header: t('contentId'),
      accessorKey: 'contentId',
      enableSorting: false,
    },
    {
      header: t('deviceId'),
      accessorKey: 'deviceId',
      enableSorting: false,
    },
  ];

  const onFilterOrSortingChange = (filteredValues) => {
    // dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    if (filteredValues?.startDate !== 'Invalid Date' && filteredValues?.endDate !== 'Invalid Date') {
      getActivityLog(1, filteredValues);
    }
  };

  useEffect(() => {
    if (modalLoader) {
      setTimeout(() => {
        setModalLoader(false);
      }, 4000);
    }
    setIsFirstRender(false);
    if (Object.keys(tableFilter).length === 0) {
      getActivityLog(1);
    }
  }, []);

  return (
    <div className="activity-log-wrap">
      {
        modalLoader ? (
          <div className="processing-wrap">
            <DotLoader
              dotActiveColor="#30A84B"
              dotBlurColor="#ACDCB7"
            />
            <h1>{t('processing')}</h1>
            <p>{t('pleaseWaitWhileTheRequestIsBeingProcessed')}</p>
          </div>
        ) : (
          <DataTable
            header={columns}
            value={deviceActiveLogs}
            status={status}
            onFilterOrSortingChange={onFilterOrSortingChange}
            reduxColumnFiltersSorting={tableFilter}
            totalPageCount={totalPageCount}
            isFirstRender={isFirstRender}
            pageCount={pageCount}
            scrollData={handleScroll}
            totalDataCount={totalDataCount}
            sorting={[]}
            rowSelection={[]}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            enable={tableFilter?.columnFilters?.length > 0}
            height="60vh"
            noRecordMessage="This feature is available from Player version 1.1.1 or higher"
            disableFullScreen
          />
        )
      }
    </div>
  );
}
ActivityLog.propTypes = {
  id: PropTypes.number,
};
ActivityLog.defaultProps = {
  id: '',
};
export default ActivityLog;
